<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pa-4"><h1 class="mb-5 text--xl font-weight-medium">{{ $t('labels.translations') }}</h1>
        <v-layout justify-end>
            <v-btn color="secondary"
                   v-if="canEditSetting"
                   @click="editItem()">New
            </v-btn>
        </v-layout>
        <v-row>
            <v-col cols="12" md="4">
                <TextField v-model.trim="itemKey"
                           clearable
                           @click:clear="itemKey = ''"
                           :label="$t('labels.key')"
                           slotName="append">
                    <template slot="slotContent">
                        <v-icon color="medium_text">{{ ICONS.SEARCH }}</v-icon>
                    </template>
                </TextField>
            </v-col>
            <v-col cols="12" md="4">
                <TextField v-model.trim="itemValue"
                           clearable
                           @click:clear="itemValue = ''"
                           :label="$t('labels.default')"
                           slotName="append">
                    <template slot="slotContent">
                        <v-icon color="medium_text">{{ ICONS.SEARCH }}</v-icon>
                    </template>
                </TextField>
            </v-col>
            <v-col cols="12" md="4">
                <TextField v-model.trim="itemInterpolation"
                           clearable
                           @click:clear="itemInterpolation = ''"
                           label="Interpolation"
                           slotName="append">
                    <template slot="slotContent">
                        <v-icon color="medium_text">{{ ICONS.SEARCH }}</v-icon>
                    </template>
                </TextField>
            </v-col>
        </v-row>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            class="elevation-1"
            disable-pagination
            hide-default-footer
            disable-sort>
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    v-if="canEditSetting"
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
            </template>
        </v-data-table>
        <v-dialog persistent
                  v-model="dialog"
                  max-width="900px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Edit</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    outlined
                                    :items="locales"
                                    :readonly="editedItem.id !== null"
                                    v-model="editedItem.locale"
                                    label="Language"
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="6">
                                <v-text-field outlined
                                              :readonly="editedItem.id !== null"
                                              v-model="editedItem.key"
                                              label="Key"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field outlined
                                              :readonly="editedItem.id !== null"
                                              v-model="editedItem.value"
                                              label="default value"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field outlined
                                              v-model="editedItem.interpolations"
                                              label="interpolation"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-layout align-center
                              justify-end
                              class="mt-3">
                        <v-btn color="secondary"
                               outlined
                               class="mr-3"
                               data-test="closeBtn"
                               @click="close">{{ $t('buttons.cancel') }}
                        </v-btn>
                        <v-btn color="secondary"
                               data-test="saveBtn"
                               :loading="loading"
                               :disabled="loading"
                               @click="save">{{ $t('buttons.save') }}
                        </v-btn>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Pagination :pagination="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import HttpService from '@/servises/HttpService';
import { getEndpoint } from '@/utils/endpoints';
import Pagination from '@/components/widgets/tables/Pagination';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import TextField from '@/components/widgets/forms/TextField';
import { ICONS } from '@/constants/icons';
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'EditLocales',
    components: { Pagination, SortableHeader, TextField },
    mixins: [paginationSettings],
    data: (vm) => ({
        ICONS,
        itemInterpolation: '',
        itemValue: '',
        itemKey: '',
        dialog: false,
        items: [],
        loading: true,
        sortingColumn: null,
        pagination: vm.setupPagination(),
        sortable: {
            id: 'asc',
            key: null,
            value: null,
            locale: null
        },
        editedItem: {
            id: null,
            value: '',
            interpolations: null,
            key: '',
            locale: ''
        },
        defaultItem: {
            id: null,
            value: '',
            interpolations: null,
            key: '',
            locale: ''
        }
    }),
    computed: {
        ...mapState('locale', [
            'locales'
        ]),
        canEditSetting () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.TRANSLATIONS);
        },
        headers () {
            return [
                {
                    text: 'Id.',
                    align: 'start',
                    value: 'id',
                    sortable: true
                },
                {
                    text: 'key',
                    value: 'key',
                    icon: false,
                    sortable: true
                },
                {
                    text: 'default value',
                    value: 'value',
                    icon: false,
                    sortable: true
                },
                {
                    text: 'interpolation',
                    value: 'interpolations',
                    icon: false,
                    sortable: true
                },
                {
                    text: 'language',
                    value: 'locale',
                    icon: false,
                    sortable: true
                },
                {
                    text: 'actions',
                    value: 'actions',
                    icon: false,
                    sortable: false
                }
            ];
        }
    },
    watch: {
        sortingColumn: {
            handler () {
                this.getLocaleMessages();
            },
            deep: true
        },
        dialog (val) {
            val || this.close();
        },
        totalPositionsAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        },
        itemKey: {
            handler (val, oldVal) {
                this.checkData(val, oldVal);
            }
        },
        itemValue: {
            handler (val, oldVal) {
                this.checkData(val, oldVal);
            }
        },
        itemInterpolation: {
            handler (val, oldVal) {
                this.checkData(val, oldVal);
            }
        }
    },
    methods: {
        getLazyData: debounce(function () {
            this.getLocaleMessages();
        }, 1000),
        checkData (val, oldVal) {
            if ((val && val.length >= 2) || (oldVal && val === '')) {
                this.pagination.page = 1;
                this.getLazyData();
            }
        },
        changePage (event) {
            this.pagination.page = event;
            this.getLocaleMessages();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.getLocaleMessages();
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.sortingColumn = {
                name: event,
                value: this.sortable[event]
            };
        },
        editItem (item) {
            if (item) {
                this.editedItem = Object.assign({}, item);
            } else this.editedItem = Object.assign({}, this.defaultItem);
            this.dialog = true;
        },
        close () {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
            });
        },
        save () {
            if (this.editedItem.id !== null) {
                const data = {
                    'data':
                        {
                            'type': 'translations',
                            'id': this.editedItem.id,
                            'attributes': {
                                'interpolations': this.editedItem.interpolations
                            }
                        }
                };
                return HttpService.patch(getEndpoint('manage_agency_translations', { id: this.editedItem.id }), data)
                    .then(() => {
                        this.close();
                        this.getLocaleMessages();
                    });
            } else {
                const data = {
                    'data':
                        {
                            'type': 'translations',
                            'attributes': {
                                'locale': this.editedItem.locale,
                                'key': this.editedItem.key,
                                'value': this.editedItem.value,
                                'interpolations': this.editedItem.interpolations
                            }
                        }
                };
                return HttpService.post(getEndpoint('agency_translations'), data)
                    .then(() => {
                        this.close();
                        this.getLocaleMessages();
                    });
            }
        },
        getLocaleMessages () {
            const params = {
                'stats[total]': 'count',
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount
            };

            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }

            if (this.itemKey) {
                params['filter[key][match]'] = this.itemKey;
            }
            if (this.itemValue) {
                params['filter[value][match]'] = this.itemValue;
            }
            if (this.itemInterpolation) {
                params['filter[interpolations][match]'] = this.itemInterpolation;
            }
            this.loading = true;
            return HttpService.get(getEndpoint('agency_translations'), {
                params: params
            }).then((resp) => {
                this.items = resp.data.data.map(e => {
                    return {
                        id: e.id,
                        ...e.attributes
                    };
                });
                this.pagination.amountAll = resp.data.meta.stats.total.count;
                this.loading = false;
            });
        }
    },
    created () {
        this.getLocaleMessages();
    }
};
</script>

<template>
    <div class="pa-4">
       <EditLocales v-if="canRead"></EditLocales>
    </div>
</template>

<script>
import EditLocales from '@/components/EditLocales';

export default {
    name: 'Locales',
    components: { EditLocales },
    computed: {
        canRead () {
            return this.$can(this.$abilityActions.READ, this.$abilitySubjects.SYSTEM_SETTINGS);
        }
    }
};
</script>
